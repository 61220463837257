<template>
  <div class="home">
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {

  },
  mounted() {
      this.$setHeaderData({title: 'Welcome'});
      window.$("body").removeClass().addClass('home');
  },
  unmounted(){
    document.body.classList.remove('home');
  },
  watch: {

  }
}
</script>
